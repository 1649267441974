import React, { Component, createContext } from "react";
import { auth } from "./firebase";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
    state = {
        user: null,
        loading: true
    };

    componentDidMount = async () => {
        auth.onAuthStateChanged(async user => {
            this.setState({ user });
            this.setState({ loading: false });
        });
    };

    render() {

        const { user, loading } = this.state;

        if (loading) {
            return null
        }

        return (
            <UserContext.Provider value={user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserProvider;