import React, { useContext } from 'react';

import {Dialog, Transition} from "@headlessui/react";

import {NotificationManager} from "react-notifications";
import {useQueryClient} from "react-query";

import {Formik} from 'formik';
import * as Yup from 'yup';
import {UserContext} from "../../services/firebase/UserProvider";
import {updateBookmark} from "../../services/api";

const ModifyBookmark = (props) => {
    const {id, bookmarkName, displayName, type, reportTag, state, isModalOpen, toggleModal, closeModal} = props;

    // User Context
    const user = useContext(UserContext)

    const queryClient = useQueryClient();

    async function handleClick(values) {
        try {
            // Delete Bookmark
            //await deleteBookmark(id)

            await updateBookmark(id, bookmarkName, values.bookmarkName, reportTag, values.bookmarkType, state)

            // Display a Notification
            NotificationManager.success('Your bookmark has been modified', 'Success');

            // Invalidate getBookmarks query to refresh query list in Navigation Bar
            await queryClient.invalidateQueries(['getBookmarks'])
            await queryClient.invalidateQueries(['getBookmarks', id])

            closeModal()
        } catch (error) {
            if (error.response.data.error === "Conflict") {
                NotificationManager.error('We were unable to modify your bookmark - a bookmark with this name already exists', 'Error')
            } else {
                NotificationManager.error('We were unable to modify your bookmark - please try again later', 'Error')
            }

            console.log(error);
        }
    }

    const renderTypes = (type) => {
        if (type === "GLOBAL") {
            return (
                <>
                    {user.email.includes('@woodfortrees.net') && (
                        <option value={"GLOBAL"}>Global</option>
                    )}
                </>
            )
        } else if (type === "ORGANISATION") {
            return (
                <>
                    <option value={"ORGANISATION"}>Organisation</option>
                </>
            )
        } else if (type === "PERSONAL") {
            return (
                <>
                    <option value={"PERSONAL"}>Personal</option>
                    <option value={"ORGANISATION"}>Organisation</option>
                </>
            )
        } else {
            return (null)
        }
    }

    return (
        <Transition show={isModalOpen} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                static
                open={isModalOpen}
                onClose={() => toggleModal()}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
              &#8203;
            </span>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-gray-800">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-700 dark:text-gray-200"
                            >
                                <div className={"flex flex-row justify-between"}>
                                    Modify Bookmark
                                    <button
                                        autoFocus={false}
                                        onClick={(e) => toggleModal()}
                                        className={"focus:outline-none"}
                                    >
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </button>
                                </div>
                            </Dialog.Title>
                            <div className="mt-2">
                                <Formik
                                    initialValues={{
                                        bookmarkName: displayName,
                                        bookmarkType: type
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            bookmarkName: Yup.string().max(30, 'Bookmark Name may not exceed 30 characters').required('Bookmark Name is required'),
                                            bookmarkType: Yup.string().oneOf(["PERSONAL", "ORGANISATION", "GLOBAL"]).required("Bookmark Type must be one of: Personal, Organisation or Global"),
                                        })
                                    }
                                    onSubmit={(values) => handleClick(values)}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          setFieldValue,
                                          isSubmitting,
                                          touched,
                                          values
                                      }) => (
                                        <form className={"px-4 py-4"} onSubmit={handleSubmit}>
                                            <label className="block text-sm text-gray-700 dark:text-gray-400">
                                                <span>Bookmark Name</span>
                                                <input
                                                    className="block w-full text-sm focus:outline-none dark:text-gray-300 form-input leading-5 focus:border-wft focus:shadow-outline-purple mt-1"
                                                    type="text"
                                                    name="bookmarkName"
                                                    id="bookmarkName"
                                                    placeholder="Bookmark Name"
                                                    value={values.bookmarkName}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {Boolean(touched.bookmarkName && errors.bookmarkName) && (
                                                    <span
                                                        className="text-xs text-red-600 dark:text-red-400">{errors.bookmarkName}</span>
                                                )}
                                            </label>

                                            <label className={"block text-sm text-gray-700 dark:text-gray-400 mt-4"}>
                                                <span>Bookmark Type</span>
                                                <select
                                                    className="block w-full text-sm focus:outline-none dark:text-gray-300 form-input leading-5 focus:border-wft focus:shadow-outline-purple mt-1"
                                                    name="bookmarkType"
                                                    id="bookmarkType"
                                                    value={values.bookmarkType}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    {renderTypes(type)}
                                                </select>
                                                {Boolean(touched.bookmarkType && errors.bookmarkType) && (
                                                    <span
                                                        className="text-xs text-red-600 dark:text-red-400">{errors.bookmarkType}</span>
                                                )}
                                            </label>

                                            <div className={"mt-3"}>
                                                <button
                                                    disabled={isSubmitting}
                                                    autoFocus={true}
                                                    type={"submit"}
                                                    className="w-full px-3 py-2 text-white bg-wft rounded-md hover:bg-wft-dark hover:outline-none"
                                                >Update Bookmark
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
};

export default ModifyBookmark;