import React, {useContext} from 'react';

import { useNavigate } from "react-router";

import decorateName from "../../../../../../../../services/utils/decorateName";
import {SidebarContext} from "../../../../../../../../context/sidebar";

const SubNavigationItem = (props) => {
    const { name, link } = props;

    const navigate = useNavigate();

    const {toggleSidebar} = useContext(SidebarContext)

    function handleClick(event) {
        event.preventDefault()

        toggleSidebar()

        navigate(link)
    }

    return (
        <div>
            {
                link ?
                    <button onClick={(event) => handleClick(event)} className={"w-full flex justify-between items-center py-3 px-4 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-gray-700 focus:outline-none"}>
                        <span className={"flex items-center"}>
                            <span className={"px-8 text-md font-medium break-all"}>{decorateName(name)}</span>
                        </span>
                    </button>
                    :
                    <button className={"w-full flex justify-between items-center py-3 px-4 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-gray-700 focus:outline-none"}>
                        <span className={"flex items-center"}>
                            <span className={"px-8 text-md font-medium break-all"}>{decorateName(name)}</span>
                        </span>
                    </button>
            }
        </div>
    )
};

export default SubNavigationItem;