import React from 'react';

const Dashboard = () => {
    return (
        <div className={"flex h-full justify-center items-center"}>
                <img className={"w-80"} src={"/logo.webp"} alt={"Wood for Trees"} />
        </div>
    )
}

export default Dashboard;