import React, { useState, useEffect } from 'react';
import { auth } from '../../services/firebase/firebase';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from "react-router";
import {NavLink, useLocation} from "react-router-dom";
import validate from '../../services/validation/yup'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Reset = () => {
    const query = useQuery();
    const navigate = useNavigate();

    const [ code, setCode ] = useState();
    const [ email, setEmail ] = useState();
    const [ success, setSuccess ] = useState(false);
    const [ failed, setFailed ] = useState(false);
    const [ codeFailed, setCodeFailed ] = useState(false);

    useEffect(() => {
        if (query.get("oobCode") && !success) {
            checkActionCode(query.get("oobCode"))
        }
    }, [ query ])

    async function checkActionCode(actionCode) {
        try {
            const result = await auth.checkActionCode(actionCode)

            setEmail(result.data.email)
            setCode(actionCode);

            if (result.operation !== "PASSWORD_RESET") {
                setCodeFailed(true);
                return
            }
        } catch (error) {
            console.log(error);
            setCodeFailed(true);
        }
    }

    const handleSubmit = async (values) => {
        setFailed(false);
        setSuccess(false);

        try {
            await auth.confirmPasswordReset(code, values.password)

            setSuccess(true);

            navigate('/auth/login', {
                state: {
                    resetSuccess: true
                }
            })
        } catch (error) {
            console.error(error);
            setFailed(true);
        }
    };

    function getValidationSchema(values) {
        return Yup.object().shape({
            password: Yup.string()
                .min(6, 'Password has to be longer than 6 characters!')
                .required('Password is required!'),
            passwordConfirmation: Yup.string()
                .oneOf([values.password], 'Passwords are not the same!')
                .required('Password confirmation is required!'),
        })
    }

    if (codeFailed || !code) {
        return (
            <div className="flex items-center min-h-screen bg-white dark:bg-gray-900">
                <div className="container mx-auto">
                    <div className="max-w-md mx-auto">
                        <div className="text-center">
                            <div className="bg-red-200 px-6 py-4 rounded-md text-lg flex items-center mx-auto w-full">
                                <div className={"flex flex-col"}>
                                    <span className={"text-red-800"}>This Password reset link is invalid. Please follow the reset password flow again</span>
                                </div>
                            </div>

                            <NavLink to={"/auth/login"}>
                                <button className={"mt-3 px-3 py-4 text-white bg-wft rounded-md hover:bg-wft-dark hover:outline-none"}>
                                    Back to Login
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex items-center min-h-screen bg-white dark:bg-gray-900">
            <div className="container mx-auto">
                <div className="max-w-md mx-auto my-10">
                    <div className="text-center">
                        <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Enter your new Password</h1>
                    </div>
                    <div className="m-7">
                        <Formik
                            initialValues={{
                                password: '',
                                passwordConfirmation: '',
                            }}
                            validate={validate(getValidationSchema)}
                            onSubmit={(values) => handleSubmit(values)}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  touched,
                                  values
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-6">
                                        <div className="flex justify-between mb-2">
                                            <label htmlFor="email"
                                                   className="text-sm text-gray-600 dark:text-gray-400">Email Address</label>
                                            <NavLink to={"/auth/login"} className="text-sm text-gray-400 focus:outline-none focus:text-wft hover:text-wft">
                                                Login instead
                                            </NavLink>
                                        </div>
                                        <input type="email" name="email" id="email" placeholder="you@company.com" autoComplete={"username"} value={email} disabled={true}
                                               className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
                                    </div>
                                    <div className="mb-6">
                                        <div className="flex justify-between mb-2">
                                            <label htmlFor="password"
                                                   className="text-sm text-gray-600 dark:text-gray-400">Password</label>
                                        </div>
                                        <input type="password" name="password" id="password" placeholder="Your Password" autoComplete={"current-password"} value={values.password} onChange={handleChange} onBlur={handleBlur}
                                               className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
                                        {Boolean(touched.password && errors.password) && (
                                            <p className="mt-2 text-red-600 text-xs italic">{errors.password}</p>
                                        )}
                                    </div>
                                    <div className="mb-6">
                                        <div className="flex justify-between mb-2">
                                            <label htmlFor="password"
                                                   className="text-sm text-gray-600 dark:text-gray-400">Confirm Password</label>
                                        </div>
                                        <input type="password" name="passwordConfirmation" id="passwordConfirmation" placeholder="Confirm Password" autoComplete={"current-password"} value={values.passwordConfirmation} onChange={handleChange} onBlur={handleBlur}
                                               className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
                                        {Boolean(touched.passwordConfirmation && errors.passwordConfirmation) && (
                                            <p className="mt-2 text-red-600 text-xs italic">{errors.passwordConfirmation}</p>
                                        )}
                                    </div>
                                    <div className="mb-6">
                                        <button type="submit" disabled={isSubmitting || success}
                                                className="w-full px-3 py-4 text-white bg-wft rounded-md hover:bg-wft-dark hover:outline-none disabled:opacity-70 disabled:cursor-default disabled:bg-wft">Reset My Password
                                        </button>
                                    </div>
                                    {failed && (
                                        <div className={"my-2"}>
                                            <div className="bg-red-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto w-full">
                                                <div className={"flex flex-col"}>
                                                    <span className={"text-red-800"}>There was an error resetting your Password. Please contact WfT support</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reset;