import React from 'react'

export const BookmarkContext = React.createContext({
    isButtonVisible: false,
    isModalOpen: true,
    toggleModal: () => {

    },
    openModal: () => {

    },
    closeModal: () => {

    }
})