import React from 'react'

export const SidebarContext = React.createContext({
    isSidebarOpen: true,
    toggleSidebar: () => {
    },
    openSidebar: () => {

    },
    closeSidebar: () => {
    }
})