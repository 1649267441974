import React, { useState, useEffect } from 'react';

import { NavigationItem } from './components'

import _ from 'lodash';
import {useQuery} from "react-query";
import {getBookmarks, getBundles, getReports} from "../../../../services/api";
import BookmarkNavigationItem from "./components/BookmarkNavigationItem";

const Sidebar = (props) => {
    const [ currentIndex, setCurrentIndex ] = useState();

    const [ appMenuItems, setAppMenuItems ] = useState([]);

    const {
        data: bundles,
    } = useQuery("getBundles", () => getBundles(), { staleTime: Infinity })

    const {
        isLoading,
        data: reports,
        error
    } = useQuery("getReports", () => getReports(), { staleTime: 600000 })

    const {
        bookmarksLoading,
        data: bookmarks,
        bookmarksError
    } = useQuery("getBookmarks", () => getBookmarks(), { staleTime: 600000 })

    async function generateMenu() {
        if (bundles) {
            if (bundles.length) {
                bundles.map((bundle, i) => {
                    bundle.items = [];

                    let matchingReports = _.filter(reports, {'bundleCategory': bundle.prefix})

                    for (const report of matchingReports) {
                        let newReport = {
                            id: report.id,
                            name: report.name,
                            link: `/report/${report.id}`,
                            bookmarks: []
                        }

                        let matchingBookmarks = _.filter(bookmarks, {'reportTag': report.reportTag})

                        newReport.bookmarks = matchingBookmarks

                        bundle.items.push(newReport)
                    }

                    setAppMenuItems(oldArray => [...oldArray, bundle])
                })
            }
        }
    }

    useEffect(() => {
        if (bundles) {
            if (bundles.length) {
                generateMenu()
            }
        }
    }, [bundles, reports, bookmarks])

    useEffect(() => {
        if (reports) {
            if (reports.length) {
                setAppMenuItems([]);
                generateMenu()
            }
        }
    }, [reports, bookmarks])

    function setIsOpen(e, index) {
        e.preventDefault();

        if (currentIndex === index) {
            setCurrentIndex(null);
        } else {
            setCurrentIndex(index);
        }
    }

    function isOpen(index) {
        if (currentIndex === index) {
            return true
        } else {
            return false
        }
    }

    return (
        <div className="w-2/4 lg:w-1/4 lg:min-w-1/4 max-h-full bg-white border-t-2 border-gray-300 overflow-y-auto overflow-x-hidden">
            {reports && reports.length ?
                <>
                    <nav>
                        {appMenuItems.map((item, index) => (
                            <NavigationItem {...item} isOpen={isOpen(index)} setIsOpen={(e) => setIsOpen(e, index)}
                                            key={index}/>
                        ))}
                    </nav>

                    <nav>
                        <BookmarkNavigationItem
                            name={"Bookmarks"}
                            items={appMenuItems}
                        />
                    </nav>
                </>
                :
                <nav className={"animate-pulse"}>
                    <div className={"border-b-2 border-gray-300 py-3 px-4"}>
                        <div className="mr-2 h-6 w-64 rounded-xl overflow-hidden relative bg-gray-200"></div>
                    </div>
                    <div className={"border-b-2 border-gray-300 py-3 px-4"}>
                        <div className="mr-2 h-6 w-64 rounded-xl overflow-hidden relative bg-gray-200"></div>
                    </div>
                    <div className={"border-b-2 border-gray-300 py-3 px-4"}>
                        <div className="mr-2 h-6 w-64 rounded-xl overflow-hidden relative bg-gray-200"></div>
                    </div>
                    <div className={"border-b-2 border-gray-300 py-3 px-4"}>
                        <div className="mr-2 h-6 w-64 rounded-xl overflow-hidden relative bg-gray-200"></div>
                    </div>
                    <div className={"border-b-2 border-gray-300 py-3 px-4"}>
                        <div className="mr-2 h-6 w-64 rounded-xl overflow-hidden relative bg-gray-200"></div>
                    </div>
                </nav>
            }
        </div>
    );
};

export default Sidebar;