import axios from 'axios';
import { auth } from './firebase/firebase'
import querystring from 'querystring';
import { v4 as uuidv4 } from 'uuid';

const getBundles = async () => {
    const idToken = await auth.currentUser?.getIdToken();

    const { data } = await axios.get(`${process.env.REACT_APP_API_GATEWAY}/bundle`, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}

const getReports = async () => {
    const idToken = await auth.currentUser?.getIdToken();

    const { data } = await axios.get(`${process.env.REACT_APP_API_GATEWAY}/report`, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });
    return data
}

const getReport = async (reportId) => {
    const idToken = await auth.currentUser?.getIdToken();

    const { data } = await axios.get(`${process.env.REACT_APP_API_GATEWAY}/report/${reportId}`, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}

const getNewUserAccessToken = async (reportId, datasetId) => {
    const idToken = await auth.currentUser?.getIdToken();

    const { data } = await axios.post(`${process.env.REACT_APP_API_GATEWAY}/report/${reportId}/token`, {
        datasetId: datasetId,
    }, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data;
}

const getBookmark = async (bookmarkID) => {
    const idToken = await auth.currentUser?.getIdToken();

    const { data } = await axios.get(`${process.env.REACT_APP_API_GATEWAY}/bookmarks/${bookmarkID}`, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}

const getBookmarks = async (reportTag) => {
    const idToken = await auth.currentUser?.getIdToken();

    let url = `${process.env.REACT_APP_API_GATEWAY}/bookmarks?`

    const query = querystring.stringify({
        reportTag: reportTag
    })

    url = url + query

    const { data } = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}

const createBookmark = async (bookmarkName, displayName, reportTag, state, type) => {
    const idToken = await auth.currentUser?.getIdToken();

    const { data } = await axios.post(`${process.env.REACT_APP_API_GATEWAY}/bookmarks`, {
        bookmarkName,
        displayName,
        reportTag,
        type,
        state
    }, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}

const updateBookmark = async (bookmarkId, bookmarkName, displayName, reportTag, type, state) => {
    const idToken = await auth.currentUser?.getIdToken();

    const { data } = await axios.put(`${process.env.REACT_APP_API_GATEWAY}/bookmarks/${bookmarkId}`, {
        id: bookmarkId,
        bookmarkName,
        displayName,
        reportTag,
        type,
        state
    }, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    })

    return data
}

const deleteBookmark = async (bookmarkId) => {
    const idToken = await auth.currentUser?.getIdToken();

    const { data } = await axios.delete(`${process.env.REACT_APP_API_GATEWAY}/bookmarks/${bookmarkId}`, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}

const getUser = async () => {
    const idToken = await auth.currentUser?.getIdToken();

    const { data } = await axios.get(`${process.env.REACT_APP_API_GATEWAY}/user`, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}

const createSessionLogging = async (sessionId, user, loginDateTime, loginStatus, connection) => {
    const idToken = await auth.currentUser?.getIdToken();
    const { data } = await axios.post(`${process.env.REACT_APP_API_GATEWAY}/user/session`, {
        sessionId,
        user,
        loginDateTime,
        loginStatus,
        connection
    }, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}

const createReportLogging = async (
    sessionId, 
    reportId, 
    reportName,
    //reportBundleCategory, 
    reportSectionId, reportSectionName, datasetId,
    //reportTag,
    bookmarkId,
    bookmarkDisplayName,
    bookmarkType,
    reportStartDateTime) => {
    const idToken = await auth.currentUser?.getIdToken();
    const loggingReportId = uuidv4();
    const { data } = await axios.post(`${process.env.REACT_APP_API_GATEWAY}/user/report`, {
        loggingReportId,
        sessionId,
        reportId,
        reportName,
        //reportBundleCategory, 
        reportSectionId,
        reportSectionName,
        datasetId,
        //reportTag,
        bookmarkId,
        bookmarkDisplayName,
        bookmarkType,
        reportStartDateTime
    }, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}


const createLogoutLogging = async (sessionId, logoutDateTime, logoutType) => {
    const idToken = await auth.currentUser?.getIdToken();

    const logoutId = uuidv4();
    const { data } = await axios.post(`${process.env.REACT_APP_API_GATEWAY}/user/logout`, {
        logoutId,
        sessionId,
        logoutDateTime,
        logoutType
    }, {
        headers: {
            Authorization: `Bearer ${idToken}`
        }
    });

    return data
}

export {
    getBundles,
    getReports,
    getReport,
    getNewUserAccessToken,
    getBookmark,
    getBookmarks,
    createBookmark,
    updateBookmark,
    deleteBookmark,
    getUser,
    createSessionLogging,
    createReportLogging,
    createLogoutLogging
}