import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { auth } from '../../services/firebase/firebase';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {useLocation} from "react-router";
import { SessionContext } from "../../context/session";

const Login = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { setLoginStatus } = useContext(SessionContext);

    const [ failed, setFailed ] = useState(false);
    const [ resetSuccessful, setResetSuccessful ] = useState(false);
    const [ error, setError ] = useState();

    function translateFirebaseResponse(code) {
        switch(code) {
            case "auth/user-not-found":
                return "There is no user record corresponding to this email address and password combination."
            case "auth/user-disabled":
                return "This user account has been disabled."
            case "auth/wrong-password":
                return "There is no user record corresponding to this email address and password combination."
            case "auth/invalid-credential":
                return "There is no user record corresponding to this email address and password combination."
            default:
                return "Please try again later"
        }
    }

    const handleSubmit = async (values) => {
        setFailed(false);

        try {
            await auth.signInWithEmailAndPassword(values.email, values.password);
            navigate('/', { replace: true });
        } catch (error) {
            console.error(error);
            setLoginStatus(error.code);
            setError(error.code);
            setFailed(true);
        }
    };

    useEffect(() => {
        if (state) {
            const { resetSuccess } = state;

            setResetSuccessful(resetSuccess)
        }
    }, [state])

    return (
        <div className="flex items-center min-h-screen bg-white dark:bg-gray-900">
            <div className="container mx-auto">
                <div className="max-w-md mx-auto">
                    <div className="text-center">
                        <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">InsightHub</h1>
                        <p className="text-gray-500 dark:text-gray-400">Sign in below</p>
                    </div>
                    <div className="m-7">
                        <Formik
                            initialValues={{
                                email: '',
                                password: ''
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                password: Yup.string().max(255).required('Password is required')
                            })}
                            onSubmit={(values) => handleSubmit(values)}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  touched,
                                  values
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-6">
                                        <label htmlFor="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email
                                            Address</label>
                                        <input type="email" name="email" id="email" autoFocus placeholder="you@company.com" autoComplete={"username"} value={values.email} onChange={handleChange} onBlur={handleBlur}
                                               className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
                                        {Boolean(touched.email && errors.email) && (
                                            <p className="mt-2 text-red-600 text-xs italic">{errors.email}</p>
                                        )}
                                    </div>
                                    <div className="mb-6">
                                        <div className="flex justify-between mb-2">
                                            <label htmlFor="password"
                                                   className="text-sm text-gray-600 dark:text-gray-400">Password</label>
                                            <NavLink to={"/auth/recover"} className="text-sm text-gray-400 focus:outline-none focus:text-wft hover:text-wft" tabIndex={-1}>
                                                Forgot password?
                                            </NavLink>
                                        </div>
                                        <input type="password" name="password" id="password" placeholder="Your Password" autoComplete={"current-password"} value={values.password} onChange={handleChange} onBlur={handleBlur}
                                               className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
                                        {Boolean(touched.password && errors.password) && (
                                            <p className="mt-2 text-red-600 text-xs italic">{errors.password}</p>
                                        )}
                                    </div>
                                    <div className="mb-6">
                                        <button type="submit" disabled={isSubmitting}
                                                className="w-full px-3 py-4 text-white bg-wft rounded-md hover:bg-wft-dark hover:outline-none">Sign in
                                        </button>
                                    </div>
                                    {resetSuccessful && (
                                        <div className={"my-2"}>
                                            <div className="bg-green-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto w-full">
                                                <span className="text-green-800">Your Password was reset - you may now login using your new credentials.</span>
                                            </div>
                                        </div>
                                    )}
                                    {failed && (
                                        <div className={"my-2"}>
                                            <div className="bg-red-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto w-full">
                                                <div className={"flex flex-col"}>
                                                    <span className={"text-red-800 font-bold text-lg"}>There was a problem logging you in.</span>
                                                    <span className="text-red-800">{translateFirebaseResponse(error)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </form>
                                )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;