import React, { useState } from 'react';
import { auth } from '../../services/firebase/firebase';

import * as Yup from 'yup';
import { Formik } from 'formik';
import {NavLink} from "react-router-dom";

const Recover = () => {
    const [ success, setSuccess ] = useState(false);
    const [ failed, setFailed ] = useState(false);

    const handleSubmit = async (values) => {
        try {
            await auth.sendPasswordResetEmail(values.email)

            setSuccess(true);
        } catch (error) {
            console.error(error);
            setFailed(true);
        }
    };

    return (
        <div className="flex items-center min-h-screen bg-white dark:bg-gray-900">
            <div className="container mx-auto">
                <div className="max-w-md mx-auto my-10">
                    <div className="text-center">
                        <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Recover your Password</h1>
                        <p className="text-gray-500 dark:text-gray-400">Enter your Email Address to begin the recovery process</p>
                    </div>
                    <div className="m-7">
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            })}
                            onSubmit={(values) => handleSubmit(values)}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  touched,
                                  values
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-6">
                                        <div className="flex justify-between mb-2">
                                            <label htmlFor="email"
                                                   className="text-sm text-gray-600 dark:text-gray-400">Email Address</label>
                                            <NavLink to={"/auth/login"} className="text-sm text-gray-400 focus:outline-none focus:text-wft hover:text-wft">
                                                Login instead
                                            </NavLink>
                                        </div>
                                        <input type="email" name="email" id="email" placeholder="you@company.com" autoComplete={"username"} value={values.email} onChange={handleChange} onBlur={handleBlur}
                                               className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"/>
                                        {Boolean(touched.email && errors.email) && (
                                            <p className="mt-2 text-red-600 text-xs italic">{errors.email}</p>
                                        )}
                                    </div>
                                    <div className="mb-6">
                                        <button type="submit" disabled={isSubmitting || success}
                                                className="w-full px-3 py-4 text-white bg-wft rounded-md hover:bg-wft-dark hover:outline-none">Reset My Password
                                        </button>
                                    </div>
                                    {failed && (
                                        <div className={"my-2"}>
                                            <div className="bg-red-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto w-full">
                                                <div className={"flex flex-col"}>
                                                    <span className={"text-red-800"}>Your request could not be processed, please try again</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {success && (
                                        <div className={"my-2"}>
                                            <div className="bg-green-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto w-full">
                                                <span className="text-green-800">If this account exists, an email will be sent with instructions to reset your Password</span>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recover;