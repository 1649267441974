import React from "react";
import {BookmarkContext} from "../context/bookmark";
import {useLocation} from 'react-router-dom';

export const BookmarkProvider = ({children}) => {
    const [isButtonVisible, setIsButtonVisible] = React.useState(false)
    const [isModalOpen, setIsModalOpen] = React.useState(false)

    let location = useLocation();

    function toggleModal() {
        setIsModalOpen(!isModalOpen)
    }

    function openModal() {
        setIsModalOpen(true)
    }

    function closeModal() {
        setIsModalOpen(false)
    }

    const setButtonVisible = (pathname) => {
        if (pathname) {
            if (pathname.includes("/report/")) {
                setIsButtonVisible(true)
            } else {
                setIsButtonVisible(false)
            }
        }
    }

    React.useEffect(() => {
        if (location) {
            setButtonVisible(location.pathname)
        }
    }, [location]);

    return (
        <BookmarkContext.Provider value={{isButtonVisible, isModalOpen, toggleModal, openModal, closeModal}}>
            {children}
        </BookmarkContext.Provider>
    )
}