import React, {useMemo, useState} from "react";
import {SidebarContext} from "../context/sidebar";

export const SidebarProvider = ({children}) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    function toggleSidebar() {
        setIsSidebarOpen(!isSidebarOpen)
    }

    function openSidebar() {
        setIsSidebarOpen(true);
    }

    function closeSidebar() {
        setIsSidebarOpen(false)
    }

    const value = useMemo(
        () => ({
            isSidebarOpen,
            toggleSidebar,
            openSidebar,
            closeSidebar,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSidebarOpen]
    )

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
}