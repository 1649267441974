import React, { useState, useEffect, useContext, Fragment } from 'react';	
import { useParams } from "react-router-dom";	
import { models } from 'powerbi-client';	
import { PowerBIEmbed } from 'powerbi-client-react';	
import { getReport, getNewUserAccessToken, createBookmark, getBookmarks, getBookmark, createReportLogging } from "../../services/api";	
import useInterval from "../../hooks/useInterval";	
import {UserContext} from "../../services/firebase/UserProvider";	
import {NotificationManager} from 'react-notifications';	
import {BookmarkContext} from "../../context/bookmark";	
import {useQueryClient} from "react-query";	
import {CreateBookmark} from "../../components";
import { SessionContext } from "../../context/session";

const Report = () => {
    // The Report ID and Bookmark of the current page
    const { id, bookmarkId } = useParams();

    const MINUTES_BEFORE_EXPIRATION = 1;
    const INTERVAL_TIME = 10000;

    // PowerBI Report object (to be received via callback)	
    const [ report, setReport ] = useState(null);	
    // Bookmark State	
    const [ bookmark, setBookmark ] = useState();	
    // State to track Current Report	
    const [ reportId, setReportId ] = useState();	
    const [ currentBookmarkId, setCurrentBookmarkId ] = useState();	
    const [ reportTag, setReportTag ] = useState();	
    const [ datasetId, setDatasetId ] = useState();	
    const [ tokenExpiration, setTokenExpiration ] = useState();	
    // Report State	
    const [ reportLoaded, setReportLoaded ] = useState(false);	
    const [ loggingReady, setLoggingReady ] = useState(false);	
    const [ currentPage, setCurrentPage ] = useState(0);	
    const [ pages, setPages ] = useState([]);	
    const [ page, setPage ] = useState();	
    // Pull in values from context	
    const {closeModal} = useContext(BookmarkContext);	
    const user = useContext(UserContext)
    const { sessionId } = useContext(SessionContext);

    useInterval(() => {
        if (report) {
            checkTokenAndUpdate(reportId, datasetId)
        }
    }, INTERVAL_TIME)

    useEffect(() => {
        if (bookmarkId) {
            getBookmarkData(bookmarkId)
        }
    }, [bookmarkId])

    useEffect(() => {
        if (bookmark && report && reportLoaded) {
            handleBookmarkChange(null, bookmark)
        }
    }, [bookmark, report, reportLoaded])


    // For Logging
    useEffect( async () => {
        if (loggingReady) {
            var reportName;
            var reportBundleCategory;

            const getReportData = async () => {
                if (reportId) {
                    const reportData = await getReport(reportId);
                    reportName = reportData.name // report name
                    reportBundleCategory = reportData.bundleCategory
                }
            }
            await getReportData();
            var bookmarkDisplayName;
            var bookmarkType;

            // Get extra detail for bookmark
            if (bookmark) {
                bookmarkDisplayName = bookmark.displayName;
                bookmarkType = bookmark.type
            }
            
            var reportSectionId;
            var reportSectionName;

            for (var i = 0; i < pages.length; i++) {
                if (currentPage === i) {
                    reportSectionId = pages[i].Name; // report section
                    
                    reportSectionName = pages[i].displayName // report section name
                }
            }

            const saveReport = async () => {
                try {
                    await createReportLogging(sessionId, reportId, reportName, 
                        //reportBundleCategory,
                        reportSectionId, reportSectionName, datasetId, 
                        //reportTag,
                        bookmarkId, bookmarkDisplayName, bookmarkType, new Date().toJSON());
                }
                catch (ex) {
                    console.log("Problem saving report data for logging")
                }
            }
            await saveReport();
            
        }
    }, [loggingReady])
    
    const queryClient = useQueryClient()

    // Default Report Configuration
    const defaultConfiguration = {
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        datasetId: undefined,
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            displayOption: models.DisplayOption.FitToWidth,
        },
    }

    const [reportConfiguration, setReportConfiguration] = useState(defaultConfiguration);
    const [error, setError] = useState(false);

    async function getBookmarkData(bookmarkId) {
        try {
            console.log('[INFO - getBookmark] - Requesting Bookmark Information for ' + bookmarkId)
            let book = await getBookmark(bookmarkId)

            console.log('[INFO - getBookmark] - Setting bookmark state')
            setBookmark(book)
        } catch (error) {
            console.log('[ERROR - getBookmark] - ' + error)
        }
    }

    async function updateToken(reportId, datasetId) {
        try {
            // Generate a new embed token
            console.log('[INFO - updateToken] - Requesting a new Access Token for ' + reportId)
            let newAccessToken = await getNewUserAccessToken(reportId, datasetId)

            if (newAccessToken.expiration !== tokenExpiration) {
                console.log('[INFO - updateToken] - New Access Token Expiration is newer than old')

                // Update the new token expiration time
                console.log('[INFO - updateToken] - Updating new Expiration Time to ' + newAccessToken.expiration)
                setTokenExpiration(newAccessToken.expiration)

                // Set the new Access Token
                console.log('[INFO - updateToken] - Setting Access Token')
                await report.setAccessToken(newAccessToken.token)

                // Reload the Report
                console.log('[INFO - updateToken] - Reloading Report')
                await report.reload()
            } else {
                console.log(`[INFO - updateToken] - New Access Token Expiry ${newAccessToken.expiration} matches old token expiration ${tokenExpiration}`)
            }
        } catch (error) {
            //console.log(error);
        }
    }

    function checkTokenAndUpdate(reportId, datasetId) {
        // Get the current time
        const currentTime = Date.now();
        const expiration = Date.parse(tokenExpiration);

        // Time until token expiration in milliseconds
        const timeUntilExpiration = expiration - currentTime;
        const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

        console.log('[INFO - checkTokenAndUpdate] - time until expiration ' + timeUntilExpiration + ' for report ' + reportId)
        console.log('[INFO - checkTokenAndUpdate] - time to update ' + timeToUpdate + ' for report ' + reportId)

        // Update the token if it is about to expired
        if (timeUntilExpiration <= timeToUpdate) {
            console.log("[INFO - checkTokenAndUpdate] - Updating report access token for " + reportId);
            updateToken(reportId, datasetId);
        }
    }

    async function renderInitialReport(reportId) {
        // Initial Entry
        console.log(`[INFO - renderInitialReport] - Rendering Initial Report for ${reportId}`)

        // Clear old state
        console.log('[INFO - renderInitialReport] - Clearing State')
        setReportConfiguration(defaultConfiguration)
        setError(false)
        setReport(null)
        setReportId()
        setCurrentBookmarkId()
        setReportTag()
        setBookmark()
        setDatasetId()
        setTokenExpiration()
        setReportLoaded(false)
        setLoggingReady(false)
        setCurrentPage(0)
        setPages([])

        try {
            console.log('[INFO - renderInitialReport] - Requesting Report Configuration')
            let reportConfiguration = await getReport(reportId)
            console.log('[INFO - renderInitialReport] - Requesting Report Credentials')
            let reportToken = await getNewUserAccessToken(reportConfiguration.id, reportConfiguration.datasetId)

            // Set new State
            console.log('[INFO - renderInitialReport] - Setting Report State')
            setReportId(reportConfiguration.id)
            setReportTag(reportConfiguration.reportTag)
            setDatasetId(reportConfiguration.datasetId)

            // Set Initial Token Expiry
            console.log(`[INFO - renderInitialReport] - Setting Token Expiry for ${reportConfiguration.id} to: ${reportToken.expiration}`)
            setTokenExpiration(reportToken.expiration)

            // Set Report Pages
            console.log('[INFO - renderInitialReport] - Setting Report Pages')
            setPages(reportConfiguration.pages)
            setCurrentPage(0);

            // Set Report Configuration
            console.log('[INFO - renderInitialReport] - Setting Report Configuration')
            setReportConfiguration({
                ...defaultConfiguration,
                accessToken: reportToken.token,
                embedUrl: reportConfiguration.embedUrl,
                datasetId: reportConfiguration.datasetId
            })
            setLoggingReady(true)
        } catch (error) {
            setError(true);
            console.log(error);
        }
    }

    // Execute everytime the Report ID is changed
    useEffect(() => {
        //setLoggingReady(false);
        //if (id === reportId && bookmarkId === currentBookmarkId) {
            // do nothing
        //} else if (id === reportId && bookmarkId !== currentBookmarkId) {
            // do nothing
        //} else if (id !== reportId && bookmarkId === currentBookmarkId) {
            renderInitialReport(id)
        //} else if (id !== reportId && bookmarkId !== currentBookmarkId) {
        //    renderInitialReport(id)
        //} else if (id === reportId && !bookmarkId) {
            // do nothing
        //} else if (id !== reportId && !bookmarkId) {
        //    renderInitialReport(id)
        //}
    }, [id, bookmarkId])

    const eventHandlersMap = new Map([
        ['loaded', function () {
            ////console.log('AIMEE: in load')
            console.log('[INFO - eventHandler] - Report has loaded');
        }],
        ['rendered', function () {
            console.log('[INFO - eventHandler] - Report has rendered');
            setReportLoaded(true);
            setError(false);
        }],
        ['error', async function (event) {
            
            if (event) {
                console.error(`[ERROR - eventHandler] - ${event.detail}`);
                console.dir(event);
                setError(true);
            }
        }],
        ['pageChanged', async function (event) {
            if (event) {
                if (event.detail) {
                    const page = event.detail.newPage;

                    setPage(page)
                }
            }
        }]
    ]);

    async function handlePageChange(e, page, i) {
        setLoggingReady(false);
        e.preventDefault();
        
        try {
            console.log(`[INFO - handlePageChange] - New Page (${page.Name}) requested by user`)

            // Get the current time
            const currentTime = Date.now();
            const expiration = Date.parse(tokenExpiration);

            // Time until token expiration in milliseconds
            const timeUntilExpiration = expiration - currentTime;
            const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

            console.log('[INFO - handlePageChange] - time until expiration ' + timeUntilExpiration + ' for report ' + reportId)
            console.log('[INFO - handlePageChange] - time to update ' + timeToUpdate + ' for report ' + reportId)

            // Update the token if it is about to expired
            if (timeUntilExpiration <= timeToUpdate) {
                console.log('[INFO - handlePageChange] - Token has expired')

                console.log('[INFO - handlePageChange] - Requesting new Access Token')
                await updateToken(reportId, datasetId)
            }

            setCurrentPage(i);

            console.log('[INFO - handlePageChange] - Changing PowerBI Page')

            const newPage = report.page(page.Name);

            // Check that the Page Exists
            if (newPage) {
                await newPage.setActive();
                setLoggingReady(true);
            } else {
                console.log(`[INFO - handlePageChange] - Requested Page ${page.Name} does not exist...`)
            }
        } catch (error) {
            setError(true);
            console.log(error)
        }
    }

    async function handleBookmarkChange(e, bookmark) {
        setLoggingReady(false);
        if (e) {
            e.preventDefault();
        }

        try {
            // Apply bookmark
            console.log('[INFO - handleBookmarkChange] - New Bookmark requested by user')
            await report.bookmarksManager.applyState(bookmark.state)

            // Get list of current pages
            console.log('[INFO - handleBookmarkChange] - Get pages')
            let reportPages = await report.getPages();

            // Retrieve the active page.
            console.log('[INFO - handleBookmarkChange] - Determine active page')
            let activePage = reportPages.filter(function (page) {
                return page.isActive
            })[0];

            // Update the Current Page Index
            console.log('[INFO - handleBookmarkChange] - Updating index...')
            const pageIndex = pages.findIndex((o) => o.Name === activePage.name)
            setCurrentPage(pageIndex)

            setCurrentBookmarkId(bookmark.id);

            NotificationManager.success('Bookmark Applied', 'Success')
            setLoggingReady(true);
        } catch (error) {
            console.log(error);
            NotificationManager.error('There was an issue applying your bookmark - please try again later.', 'Error')
        }
    }

    async function createReportBookmark(values) {
        try {
            // Create the bookmark in PowerBI
            const bookmark = await report.bookmarksManager.capture()

            // Persist the bookmark to the database
            await createBookmark(bookmark.name, values.bookmarkName, reportTag, bookmark.state, values.bookmarkType)

            // Invalidate getBookmarks query to refresh query list in Navigation Bar
            await queryClient.invalidateQueries(['getBookmarks'])
            await queryClient.invalidateQueries(['getBookmarks', id])

            // Close Modal
            closeModal();

            // Display a Notification
            NotificationManager.success('Your bookmark has been created', 'Success');
        } catch (error) {
            if (error.response.data.error === "Conflict") {
                NotificationManager.error('We were unable to create your bookmark - a bookmark with this name already exists', 'Error')
            } else {
                NotificationManager.error('We were unable to create your bookmark - please try again later', 'Error')
            }

            console.log(error);
        }
    }

    if (error) {
        return (
            <div className={"flex flex-col h-full"}>
                <div
                    className="bg-red-200 px-6 py-4 mb-4 h-20 sm:h-12 md:h-16 rounded-md text-lg flex items-center w-4/4 xl:w-2/4"
                >
                    <svg viewBox="0 0 24 24" className="text-red-600 w-4 h-4 sm:w-4 sm:h-4 mr-3">
                        <path fill="currentColor"
                            d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
                        />
                    </svg>
                    <span className="text-red-800 text-sm">We were unable to retrieve your InsightHub report. Please contact WfT support.</span>
                </div>
            </div>
        )
    }

    return (
        <div className={"flex flex-col h-full w-full"}>
            {reportLoaded && (pages.length !== 0 && (
                <div className="flex w-full bg-white mb-3 overflow-x-auto">
                    <nav className="flex flex-row flex-shrink mx-auto">
                        {pages.map((page, i) => {
                            if (currentPage === i) {
                                return (
                                    <button
                                        key={i}
                                        className="text-wft-grey py-3 px-6 block focus:outline-none border-b-4 font-medium border-wft mx-1 w-48 max-w-xs"
                                        onClick={(e) => handlePageChange(e, page, i)}>
                                        {page.displayName}
                                    </button>
                                )
                            } else {
                                return (
                                    <button
                                        key={i}
                                        className="text-wft-grey py-3 px-6 block hover:text-wft-dark focus:outline-none font-medium border-b-4 border-grey-300 w-48 max-w-xs mx-1 hover:border-wft-dark"
                                        onClick={(e) => handlePageChange(e, page, i)}>
                                        {page.displayName}
                                    </button>
                                )
                            }
                        })}
                    </nav>
                </div>
            ))}

            {reportConfiguration.embedUrl ? (
                <PowerBIEmbed
                    embedConfig={reportConfiguration}
                    eventHandlers={eventHandlersMap}
                    cssClassName={"flex flex-grow w-full mb-3"}
                    getEmbeddedComponent={(embeddedComponent => {
                        setReport(embeddedComponent)
                    })}
                />
            ) : (
                <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
                    <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div>
                </div>
            )}

            <CreateBookmark createReportBookmark={createReportBookmark} />
        </div>
    );
};

export default Report;
