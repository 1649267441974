import React, { useState } from 'react';

import { BookmarkItem } from '../';

import decorateName from "../../../../../../../../services/utils/decorateName";

const ReportItem = (props) => {
    const { name, bookmarks = [], id } = props;

    const [ isOpen, setIsOpen ] = useState(false);

    const isExpandable = bookmarks && bookmarks.length > 0

    function handleClick(e) {
        e.preventDefault()

        if (isExpandable) {
            setIsOpen(e, !isOpen);
        }
    }

    if (!isExpandable) {
        return (null)
    }

    return (
        <div>
            <button onClick={(e) => setIsOpen(!isOpen)}
                    className={isExpandable ? "w-full flex justify-between items-center py-3 px-4 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-gray-700 focus:outline-none" : "w-full flex justify-between items-center py-3 px-4 cursor-not-allowed bg-gray-100 text-gray-700 focus:outline-none"}>
                <span className={"flex items-center"}>
                    <span className={"px-8 mx-4 font-medium"}>{decorateName(name)}</span>
                </span>

                {isExpandable && (
                    <span>
                        <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {isOpen ?
                                <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"></path>
                                :
                                <path d="M9 5L16 12L9 19" stroke="currentColor" strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"></path>
                            }
                        </svg>
                    </span>
                )}
            </button>

            {isOpen && (
                <div>
                    {bookmarks.map((item, index) => (
                        <BookmarkItem {...item} key={index} reportId={id} />
                    ))}
                </div>
            )}
        </div>
    )
};

export default ReportItem;