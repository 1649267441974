import React from 'react';

import {Dialog, Transition} from "@headlessui/react";

import {NotificationManager} from "react-notifications";
import {useQueryClient} from "react-query";

import {deleteBookmark} from "../../services/api";

const DeleteBookmark = (props) => {
    const { id, displayName, isModalOpen, toggleModal, closeModal } = props;

    const queryClient = useQueryClient();

    async function handleClick(event) {
        if (event) {
            event.preventDefault();
        }

        try {
            // Delete Bookmark
            await deleteBookmark(id)

            // Display a Notification
            NotificationManager.success('Your bookmark has been deleted', 'Success');

            // Invalidate getBookmarks query to refresh query list in Navigation Bar
            await queryClient.invalidateQueries(['getBookmarks'])
            await queryClient.invalidateQueries(['getBookmarks', id])

            closeModal()
        } catch (error) {
            NotificationManager.error('We were unable to delete your bookmark - please try again later', 'Error')

            console.log(error);
        }
    }

    return (
        <Transition show={isModalOpen} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                static
                open={isModalOpen}
                onClose={() => toggleModal()}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
              &#8203;
            </span>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-gray-800">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-700 dark:text-gray-200"
                            >
                                <div className={"flex flex-row justify-between"}>
                                    Delete Bookmark
                                    <button
                                        autoFocus={false}
                                        onClick={(e) => toggleModal()}
                                        className={"focus:outline-none"}
                                    >
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </button>
                                </div>
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    This action <span className={"font-bold"}>cannot</span> be undone.
                                </p>
                                <p className={"text-sm text-gray-500 mt-2"}>
                                    This will permanently delete the <span
                                    className={"font-bold underline"}>{displayName}</span> bookmark.
                                </p>
                            </div>

                            <div className="mt-4">
                                <button
                                    className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-red-600 border border-transparent active:bg-red-600 hover:bg-red-700 focus:shadow-outline-purple"
                                    type="button"
                                    onClick={(event) => handleClick(event)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-t mr-2 -ml-1"
                                         fill="none" viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                    </svg>
                                    <span>Delete it, anyway!</span></button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
};

export default DeleteBookmark;