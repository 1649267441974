import React, { useContext, useState } from 'react';

import { useNavigate } from "react-router";

import {SidebarContext} from "../../../../../../../../context/sidebar";
import {DeleteBookmark, ModifyBookmark} from "../../../../../../../../components";
import {UserContext} from "../../../../../../../../services/firebase/UserProvider";
import ReactTooltip from "react-tooltip";

const BookmarkItem = (props) => {
    const { displayName, bookmarkName, type, id, reportId, reportTag, state } = props;

    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);
    const [ isModifyModalOpen, setIsModifyModalOpen ] = useState(false);

    const navigate = useNavigate();

    const user = useContext(UserContext)

    const {toggleSidebar} = useContext(SidebarContext)

    function handleClick(event) {
        event.preventDefault()

        toggleSidebar()

        navigate(`/report/${reportId}/${id}`)
    }

    function toggleDeleteModal() {
        setIsDeleteModalOpen(!isDeleteModalOpen)
    }

    function closeDeleteModal() {
        setIsDeleteModalOpen(false)
    }

    function toggleModifyModal() {
        setIsModifyModalOpen(!isModifyModalOpen)
    }

    function closeModifyModal() {
        setIsModifyModalOpen(false)
    }

    const renderIcon = (type) => {
        switch(type) {
            case "GLOBAL":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
                    </svg>
                )
            case "ORGANISATION":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                    </svg>
                )
            case "PERSONAL":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                    </svg>
                )
        }
    }

    return (
        <>
            <div className={"flex flex-row min-w-0 w-full justify-between items-center py-3 pl-4 text-gray-700 hover:bg-gray-100 focus:outline-none"}>
                <div className={"pl-8 ml-4"}>
                    {renderIcon(type)}
                </div>

                <div className={"w-2/6 xl:w-5/12 2xl:w-6/12 3xl:w-4/5 3xl:min-w-60 mr-auto ml-4"}>
                    <button
                        className={"inline-flex w-full"}
                        onClick={(e) => handleClick(e)}
                    >
                        <div className={"truncate"}>
                            <span>{displayName}</span>
                        </div>
                    </button>
                </div>

                {type == 'GLOBAL' ?
                    user.email.includes('@woodfortrees.net') && (
                        <div className={"w-16"}>
                            <button data-tip="Delete" className={"text-wft-grey hover:text-wft-light focus:outline-none"} onClick={(e) => {
                                setIsModifyModalOpen(false)
                                setIsDeleteModalOpen(true)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                </svg>
                            </button>

                            <button data-tip="Edit" className={"text-wft-grey hover:text-wft-light focus:outline-none"} onClick={(e) => {
                                setIsDeleteModalOpen(false)
                                setIsModifyModalOpen(true)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                </svg>
                            </button>
                        </div>
                    )
                    :
                    (
                        <div className={"w-16"}>
                            <button data-tip="Delete" className={"text-wft-grey hover:text-wft-light focus:outline-none"} onClick={(e) => {
                                setIsModifyModalOpen(false)
                                setIsDeleteModalOpen(true)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                </svg>
                            </button>

                            <button data-tip="Edit" className={"text-wft-grey hover:text-wft-light focus:outline-none"} onClick={(e) => {
                                setIsDeleteModalOpen(false)
                                setIsModifyModalOpen(true)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                </svg>
                            </button>
                        </div>
                    )
                }

            </div>

            <DeleteBookmark id={id} displayName={displayName} isModalOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} closeModal={closeDeleteModal} />

            <ModifyBookmark id={id} displayName={displayName} type={type} reportTag={reportTag} state={state} bookmarkName={bookmarkName} isModalOpen={isModifyModalOpen} toggleModal={toggleModifyModal} closeModal={closeModifyModal} />

            <ReactTooltip />
        </>
    )

    /*return (
        <>
            <div
                className={"w-full flex justify-between items-center py-3 px-4 text-gray-700 hover:bg-gray-100 hover:text-gray-700 focus:outline-none"}>
                <div className={"w-4/5"} style={{maxWidth: "80%"}}>
                    <button onClick={(e) => handleClick(e)}>
                        <span className={"flex justify-between items-center overflow-hidden overflow-ellipsis"}>
                            <span className={"pl-8 ml-4 font-medium items-center overflow-hidden overflow-ellipsis"}>
                                <div>
                                    <div className={"inline-block"}>
                                        {renderIcon(type)}
                                    </div>

                                    <div className={"inline-block"}>
                                        {displayName}
                                    </div>
                                </div>
                            </span>
                        </span>
                    </button>
                </div>

                {type == 'GLOBAL' ?
                    user.email.includes('@woodfortrees.net') && (
                        <div className={"flex items-center"}>
                            <button data-tip="Delete" className={"text-wft-grey hover:text-wft-light focus:outline-none"} onClick={(e) => {
                                setIsModifyModalOpen(false)
                                setIsDeleteModalOpen(true)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                </svg>
                            </button>

                            <button data-tip="Edit" className={"text-wft-grey hover:text-wft-light focus:outline-none"} onClick={(e) => {
                                setIsDeleteModalOpen(false)
                                setIsModifyModalOpen(true)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                </svg>
                            </button>
                        </div>
                    )
                :
                    (
                        <div className={"flex items-center"}>
                            <button data-tip="Delete" className={"text-wft-grey hover:text-wft-light focus:outline-none"} onClick={(e) => {
                                setIsModifyModalOpen(false)
                                setIsDeleteModalOpen(true)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                </svg>
                            </button>

                            <button data-tip="Edit" className={"text-wft-grey hover:text-wft-light focus:outline-none"} onClick={(e) => {
                                setIsDeleteModalOpen(false)
                                setIsModifyModalOpen(true)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                </svg>
                            </button>
                        </div>
                    )
                }
            </div>

            <DeleteBookmark id={id} displayName={displayName} isModalOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} closeModal={closeDeleteModal} />

            <ModifyBookmark id={id} displayName={displayName} type={type} reportTag={reportTag} state={state} bookmarkName={bookmarkName} isModalOpen={isModifyModalOpen} toggleModal={toggleModifyModal} closeModal={closeModifyModal} />

            <ReactTooltip />
        </>
    )*/
};

export default BookmarkItem;