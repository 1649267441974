import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';

function toTitleCase(str) {
    return str.replace(/\b\w+/g, function (s) {
        return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
    })
}

const Breadcrumbs = () => {
    let location = useLocation()

    let report = false;

    const pathNames = location.pathname.split('/').filter((x) => x)

    if (pathNames[0] === "report" && pathNames.length === 2) {
        report = true
    }

    return (
        <nav className="bg-gray-200 p-3 rounded font-sans w-full">
            <ol className="list-reset flex text-grey-dark">
                <li>
                    <NavLink to={"/"} className={"text-blue font-bold"}>Home</NavLink>
                </li>
                {pathNames.length !== 0 && (
                    <li><span className="mx-2">/</span></li>
                )}

                {pathNames.map((value, index) => {
                    const last = index === pathNames.length - 1
                    const to = `/${pathNames.slice(0, index + 1).join('/')}`

                    return last ? (
                        report ? <li>hi</li> : <li key={index}>{toTitleCase(value)}</li>
                    ) : (
                        <React.Fragment key={index}>
                            <li>
                                <NavLink to={to}
                                         className={"text-blue font-bold"}>{toTitleCase(value, report)}</NavLink>
                            </li>
                            <li><span className="mx-2">/</span></li>
                        </React.Fragment>
                    )
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;