import React, { useState } from 'react';

import { BundleItem } from "./components";

const BookmarkNavigationItem = (props) => {
    const { name, items = [] } = props;

    const [ isOpen, setIsOpen ] = useState(false);

    const [ currentIndex, setCurrentIndex ] = useState();

    const isExpandable = items && items.length > 0

    function handleClick(e) {
        e.preventDefault()

        setIsOpen(!isOpen)
        setCurrentIndex()
    }

    function bundleSetIsOpen(e, index) {
        e.preventDefault();

        if (currentIndex === index) {
            setCurrentIndex(null);
        } else {
            setCurrentIndex(index);
        }
    }

    function bundleIsOpen(index) {
        if (currentIndex === index) {
            return true
        } else {
            return false
        }
    }

    return (
        <div className={"border-t-2 border-b-2 border-gray-300"}>
            <button onClick={(e) => handleClick(e)}
                    className={isExpandable ? "w-full flex justify-between items-center py-3 px-4 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-gray-700 focus:outline-none" : "w-full flex justify-between items-center py-3 px-4 cursor-not-allowed bg-gray-100 text-gray-700 focus:outline-none"}>
                <span className={"flex items-center"}>
                    <span className={"flex items-center align-middle mx-4 font-medium"}>
                        {name}

                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"/>
                        </svg>
                    </span>
                </span>

                {isExpandable && (
                    <span>
                        <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {isOpen ?
                                <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"></path>
                                :
                                <path d="M9 5L16 12L9 19" stroke="currentColor" strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"></path>
                            }
                        </svg>
                    </span>
                )}
            </button>

            {isOpen && (
                <div>
                    {items.map((item, index) => (
                        <BundleItem {...item} key={index} isOpen={bundleIsOpen(index)} setIsOpen={(e) => bundleSetIsOpen(e, index)} />
                    ))}
                </div>
            )}
        </div>
    )
};

export default BookmarkNavigationItem;