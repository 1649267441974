
import { createContext } from "react";

export const SessionContext = createContext({ 
    sessionId: null, 
    user: null, 
    loginDateTime: null, 
    loginStatus: null, 
    connection: null });

