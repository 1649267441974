import React, { useState } from 'react';

import { ReportItem } from '../'

const BundleItem = (props) => {
    const { name, items = [], isOpen, setIsOpen } = props;

    const isExpandable = items && items.length > 0

    var reportsFound = false;

    items.forEach(function(item) {
        if (item.bookmarks.length > 0) {
            reportsFound = true
        }
    })

    if (!reportsFound) {
        return (null)
    }

    return (
        <div className={"border-b-2 border-gray-300"}>
            <button onClick={(e) => setIsOpen(e)}
                    className={isExpandable ? "w-full flex justify-between items-center py-3 px-4 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-gray-700 focus:outline-none" : "w-full flex justify-between items-center py-3 px-4 cursor-not-allowed bg-gray-100 text-gray-700 focus:outline-none"}>
                <span className={"flex items-center"}>
                    <span className={"px-4 mx-4 font-medium"}>{name}</span>
                </span>

                {isExpandable && (
                    <span>
                        <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {isOpen ?
                                <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"></path>
                                :
                                <path d="M9 5L16 12L9 19" stroke="currentColor" strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"></path>
                            }
                        </svg>
                    </span>
                )}
            </button>

            {isOpen && (
                <div>
                    {items.map((item, index) => (
                        <ReportItem {...item} key={index} />
                    ))}
                </div>
            )}
        </div>
    )
};

export default BundleItem;