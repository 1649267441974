import React from 'react';
import { Navigate } from 'react-router-dom';
import { Main } from './layouts';
import {Dashboard, Report, Login, Recover, Reset} from './views';

const routes = (user) => [
    {
        path: '/',
        element: user ? <Main /> : <Navigate to={"/auth/login"} />,
        children: [
            { path: '', element: <Dashboard /> },
            { path: 'report', element: <Dashboard /> },
            { path: 'report/:id/:bookmarkId', element: <Report /> },
            { path: 'report/:id', element: <Report /> },
            { path: '*', element: <Navigate to={"/"} /> }
        ]
    },
    {
        path: '/auth',
        children: [
            { path: 'login', element: <Login /> },
            { path: 'recover', element: <Recover /> },
            { path: 'reset', element: <Reset /> },
            { path: "*", element: <Navigate to={"/auth/login"} /> }
        ]
    }
]

export default routes;