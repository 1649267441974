import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-notifications/lib/notifications.css';
import { ThemeProvider } from "./theme/ThemeContext";
import UserProvider from "./services/firebase/UserProvider";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { QueryClient, QueryClientProvider } from "react-query";
import { SidebarProvider } from "./provider/sidebar";
import { BookmarkProvider } from "./provider/bookmark";
import { SessionProvider } from "./provider/session";

const queryClient = new QueryClient();

ReactDOM.render(
    // <React.StrictMode>
    <ThemeProvider>
        <SidebarProvider>
            <UserProvider>
                <BrowserRouter>
                    <SessionProvider>
                        <BookmarkProvider>
                            <QueryClientProvider client={queryClient}>
                                <App />
                            </QueryClientProvider>
                        </BookmarkProvider>
                    </SessionProvider>
                </BrowserRouter>
            </UserProvider>
        </SidebarProvider>
    </ThemeProvider>
    // </React.StrictMode >
    ,
    document.getElementById('root')
);