import React, {useContext, useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';

import {Sidebar, Topbar} from "./components";
import {useQuery, useQueryClient} from "react-query";
import {getBundles, getReports} from "../../services/api";
import {SidebarContext} from "../../context/sidebar";
import {NotificationContainer} from 'react-notifications';

const Main = () => {
    const {isSidebarOpen} = useContext(SidebarContext)
    const {state} = useContext(SidebarContext)

    const queryClient = useQueryClient();

    const {
        isLoading,
        data: bundles,
        error
    } = useQuery("getBundles", () => getBundles(), { staleTime: Infinity })

    useEffect(() => {
        const prefetchData = async () => {
            await queryClient.prefetchQuery('getReports', getReports)
        }

        prefetchData()
    }, [])

    return (
        <div className={"flex flex-col w-screen h-screen bg-gray-100 overflow-x-hidden"}>
            <Topbar bundles={bundles} />

            <div className="bg-gray-200 h-full font-sans overflow-y-hidden">
                <div className="flex flex-row h-full">
                    {isSidebarOpen && (
                        <Sidebar />
                    )}

                    <div className={"flex flex-1 flex-col pt-3 px-3 overflow-x-auto"}>
                        <Outlet />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );
};

export default Main;