import React, { useContext, useState, useEffect } from 'react';

import { auth } from '../../../../services/firebase/firebase'

import { NavLink } from 'react-router-dom';
import {UserContext} from "../../../../services/firebase/UserProvider";
import {useParams, useLocation} from "react-router-dom";
import axios from "axios";
import decorateName from "../../../../services/utils/decorateName";
import _ from "lodash";
import {SidebarContext} from "../../../../context/sidebar";
import {BookmarkContext} from "../../../../context/bookmark";
import {SessionContext} from "../../../../context/session";
import ReactTooltip from 'react-tooltip';
import { createLogoutLogging } from '../../../../services/api'

const Topbar = (props) => {
    const { bundles } = props;
    const { id } = useParams();
    let location = useLocation();

    const {toggleSidebar, openSidebar} = useContext(SidebarContext)

    const [ bundleName, setBundleName ] = useState();

    const [ report, setReport ] = useState();

    const user = useContext(UserContext)

    const {isButtonVisible, toggleModal} = useContext(BookmarkContext)
    const { sessionId, setLoginStatus } = useContext(SessionContext);

    async function getReportConfiguration(reportID) {
        try {
            const idToken = await user.getIdToken();

            const reportData = await axios.get(`${process.env.REACT_APP_API_GATEWAY}/report/${reportID}`, {
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            });

            setReport(reportData.data)
        } catch (error) {
            console.log(error);
        }
    }

    const handleBookmark = (e) => {
        e.preventDefault();

        toggleModal();
    }

    useEffect(() => {
        if (location.pathname) {
            if (location.pathname.includes('/report/')) {
                let reportID = location.pathname.replace('/report/', '')

                if (reportID.includes('/')) {
                    reportID = reportID.split('/')[0]

                    getReportConfiguration(reportID)
                } else {
                    getReportConfiguration(reportID)
                }
            } else {
                setBundleName()
                setReport()
                openSidebar()
            }
        }
    }, [location])

    useEffect(() => {
        if (report) {
            if (report.bundleCategory) {
                try {
                    let matchingBundle = _.find(bundles, { 'prefix': report.bundleCategory })

                    setBundleName(matchingBundle.name)
                } catch (error) {
                    console.log('failed to set bundle name: ' + error)

                    setBundleName('')
                }
            }
        }
    }, [report])

    return (
        <header className="flex bg-white h-16">
            <div className="w-2/5 px-4 py-3 hidden sm:flex sm:flex-row items-center">
                <div className={"h-full"}>
                    <button onClick={(e) => toggleSidebar()} className={"h-10 w-10 text-wft-grey hover:text-wft-light justify-self-start focus:outline-none"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>

                <div className={"ml-8"}>
                    <NavLink to={"/"}>
                        <img
                            className="h-7"
                            src={process.env.REACT_APP_LOGO_URI}
                            alt="Wood for Trees"
                        />
                    </NavLink>
                </div>

                {(bundleName) && (report) && (
                    <h1 className={"ml-8 text-lg text-wft-grey justify-self-start"}>{bundleName} - {decorateName(report.name)}</h1>
                )}
            </div>

            <div className="flex-1 flex items-center justify-end sm:px-6">
                <nav className="flex">
                    {isButtonVisible && (
                        <>
                            <div className={"flex relative items-center justify-end text-wft-grey mr-4"}>
                                <button
                                    data-tip="Add Bookmark"
                                    className={"ml-2 inline-block px-4 py-2 leading-none text-sm text-wft-grey hover:text-wft-light focus:outline-none"} onClick={(e) => handleBookmark(e)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"/>
                                    </svg>
                                </button>
                            </div>

                            <ReactTooltip />
                        </>
                    )}

                    <div className={"flex relative items-center justify-end text-wft-grey"}>
                        <svg className={"h-4 w-4"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        <span className={"ml-2"}>{user.email}</span>
                    </div>

                    <button data-tip="Logout" className={"ml-2 inline-block px-4 py-2 leading-none text-sm text-wft-grey hover:text-wft-light focus:outline-none"} 
                        onClick={(e) => {
                            setLoginStatus('success'); 
                            createLogoutLogging(sessionId, new Date().toJSON(), 'user logout'); 
                            auth.signOut()}}>
                        <svg className={"h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                    </button>
                </nav>
            </div>
        </header>
    );
};

export default Topbar;