import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { auth } from "../services/firebase/firebase";
import { getUser } from "../services/api";
import { SessionContext } from "../context/session";
import { createSessionLogging } from "../services/api";


export const SessionProvider = ({ children }) => {

    const [sessionId, setSessionId] = React.useState('')
    const [user, setUser] = React.useState(null)
    //const [uid, setUid] = React.useState(null)
    //const [groupId, setGroupId] = React.useState(null)
    //const [appId, setAppId] = React.useState(null)
    //const [reportMetadata, setReportMetadata] = React.useState(null)
    const [loginDateTime, setLoginDateTime] = React.useState(null)
    const [connection, setConnection] = React.useState(null)
    const [loginStatus, setLoginStatus] = React.useState('success')
    const [sessionCreated, setSessionCreated] = React.useState(false)

    // Gets data needed to store for the session, such as creating a session ID and retrieving information relating to the user
    React.useEffect(() => {
        auth.onAuthStateChanged(async user => {
            setSessionCreated(false);
            if (user && sessionId === '') {
                var userData;
                const getUserInfo = async () => {
                    userData = await getUser();
                }

                await getUserInfo();

                const id = uuidv4();
                setSessionId(id);// Set Session Id
                setUser(userData);
                setLoginDateTime(new Date().toJSON()); // toJSON needed to make date format compatible with golang Time package

                if (window.location.href.includes('development')) {
                    setConnection('dev');
                } 
                else if (window.location.href.includes('localhost')) {
                    setConnection('localhost');
                }
                else {
                    setConnection('prod');
                } 
                
                setSessionCreated(true);
            }
        })
    }, []);

    // Saves session info back to Firestore database - only triggered once session data is created
    React.useEffect(() => {
            if (sessionCreated) {
                const saveSession = async () => {
                    try {
                        await createSessionLogging(sessionId, user, loginDateTime, loginStatus, connection);
                    }
                    catch (ex){
                        console.log("Problem saving session data for logging")
                    }
                }
                saveSession();
            }
    }, [sessionCreated]);

    return (
        <SessionContext.Provider value={{ sessionId, user, loginDateTime, loginStatus, connection, setLoginStatus }}>
            {children}
        </SessionContext.Provider>
    );
}