import React, { useContext } from 'react';

import { UserContext } from './services/firebase/UserProvider';
import { SessionContext } from './context/session';
import { auth } from './services/firebase/firebase';
import { useRoutes } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
import routes from './routes';
import { createLogoutLogging } from './services/api'

function App() {
    const user = useContext(UserContext);
    const { sessionId } = useContext(SessionContext);

    const handleOnIdle = async (event) => {
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())

        if (auth.currentUser) {
            console.log('logging out user')
            await createLogoutLogging(sessionId, new Date().toJSON(), 'timeout');
            await auth.signOut();
        }
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 60,
        onIdle: handleOnIdle,
        debounce: 500
    })

    return useRoutes(routes(user))
}

export default App;
